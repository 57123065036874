import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    passDetails: PropTypes.object,
    currentPage: PropTypes.number,
    selectedCruise: PropTypes.object,
    handleNavButtonClick: PropTypes.func,
}

const SeasonPassCustomerDetails = ({passDetails, currentPage, selectedCruise, handleNavButtonClick}) => {
    return (
        <div className="customer-cruise-details">
            <div className="flex space-between">
                <h2>Customer Details</h2>
                {currentPage === 2 &&  
                    <button 
                        className="text-link"
                        onClick={() => handleNavButtonClick(1)}
                    >
                        Back
                    </button> 
                }
            </div>
            <div className="pass-details boarding-pass">
                <div className="pass-type-card-body">
                   SeasonPass Barcode: {passDetails.visualID}
                </div>
                <div className="pass-type-card-body">
                    Customer Name: {passDetails.firstName} {passDetails.lastName}
                </div>
                <div className="pass-type-card-body"> 
                    Email: {passDetails.email}
                </div>
                <div className="pass-type-card-body"> 
                    Phone: {passDetails.phone}
                </div>
                <div className="pass-type-card-body"> 
                    Zip: {passDetails.zip}
                </div>
            </div>
            {currentPage === 2 && 
                <>
                    <h2>Cruise Details</h2>
                    <div className="pass-details boarding-pass">
                        <div className="pass-type-card-body"> 
                            Name: {selectedCruise?.displayName}
                        </div>
                        <div className="pass-type-card-body"> 
                            Description: {selectedCruise?.description}
                        </div>
                    </div>
                </>
            }
            
        </div>
    )
}

SeasonPassCustomerDetails.propTypes = propTypes;
export default SeasonPassCustomerDetails;