export function basketTag(cart, adultTicketQty, childTicketQty) {
  if (window.criteo_q) {
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? 'm'
      : 'd'

    window.criteo_q.push(
      { event: 'setAccount', account: 60370 },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: '' },
      {
        event: 'viewSearch',
        checkin_date: cart.eventStartDate,
        nbra: adultTicketQty,
        nbrc: childTicketQty,
      },
      {
        event: 'viewBasket',
        item: [
          {
            id: cart.eventName,
            price: cart.total,
            quantity: 1,
          },
        ],
      }
    )
  }
}

export function salesTag(cart, adultTicketQty, childTicketQty, orderNumber) {
  if (window.criteo_q) {
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? 'm'
      : 'd'

    window.criteo_q.push(
      { event: 'setAccount', account: 60370 },
      { event: 'setSiteType', type: deviceType },
      { event: 'setEmail', email: cart.email },
      {
        event: 'viewSearch',
        checkin_date: cart.eventStartDate,
        nbra: adultTicketQty,
        nbrc: childTicketQty,
      },
      {
        event: 'trackTransaction',
        id: orderNumber,
        item: [{ id: cart.eventName, price: cart.total, quantity: 1 }],
      }
    )
  }
}
