import React, { useState } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { SubmitButton } from 'lp-components'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const CouponForm = ({ onSubmit }) => {
  return (
    <React.Fragment>
      <h2>Gift Cards & Promos</h2>
      <Formik
        initialValues={{ coupon: '' }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values)
          resetForm()
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="promo-form reseller-promo">
            <input
              type="text"
              name="coupon"
              placeholder="Enter Promotional Code"
              onChange={handleChange}
              value={values.coupon}
              className="promo-input"
            />
            <SubmitButton submitting={isSubmitting}>Add</SubmitButton>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )
}
CouponForm.propTypes = propTypes
export default CouponForm
