import React, { useState } from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

const propTypes = {
  eventTimes: PropTypes.array.isRequired,
  selectedEventId: PropTypes.number,
  updateCartCalendarEvent: PropTypes.func.isRequired,
  setShowTicketSelector: PropTypes.func.isRequired,
  setLoadingNewPrice: PropTypes.func.isRequired,
  loadingNewPrice: PropTypes.bool,
  isReseller: PropTypes.bool
}

function getPrice(event, isReseller){
  const ticket = event?.tickets?.find(item => item.ticketType === 'adult')
  if (isReseller && ticket?.sellPrice){
    return `$${ticket.sellPrice}`;
  } else if (ticket?.price){
    return `$${ticket.price}`;
  }
}

const BoardingPassTimes = ({
  eventTimes,
  selectedEventId,
  updateCartCalendarEvent,
  setShowTicketSelector,
  setLoadingNewPrice,
  loadingNewPrice,
  isReseller = false
}) => {
  const [timeSelected, setTimeSelected] = useState(selectedEventId ? selectedEventId : 0)

  return (
    <div>
      <hr className="boarding-pass-rule" />
      <div className="event-buttons-container">
        {eventTimes.map((event) => (
          <div className="event-button-column" key={`event-column-${event.id}`}>
            <button
              type="button"
              value={event.id}
              disabled={event.soldOut || loadingNewPrice}
              onClick={() => {
                if (timeSelected !== event.id) {
                  updateCartCalendarEvent(event)
                  setTimeSelected(parseInt(event.id))
                  setShowTicketSelector(false)
                  setLoadingNewPrice(true) 
                }
              }}
              className={classnames('boarding-pass-button', {
                'sold-out-button': event.soldOut,
                'disabled-button': loadingNewPrice,
                'time-selected-button': timeSelected === event.id,
              })}
            >
              {moment(event.startDateTime).utc().format('h:mm A')}
              <div>
                {getPrice(event, isReseller)}
              </div>
            </button>
            <div className="availability-text">
              {event.soldOut
                ? 'Sold Out'
                : `${pluralize('Seat', event.available, true)} Left`}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
BoardingPassTimes.propTypes = propTypes
export default BoardingPassTimes
