import React, { useState } from 'react'
import { MemberModal, Members, PassTicketPicker } from 'components'
import * as api from 'api'
import PropTypes from 'prop-types'
import { isEmpty, findLast, first } from 'lodash'
import { ticketQuantityMap } from 'utils'

const propTypes = {
  webstore: PropTypes.string.isRequired,
  currentCart: PropTypes.object.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
  selectedPass: PropTypes.object.isRequired,
}

const defaultProps = {}

const SeasonPassDetails = ({
  webstore,
  currentCart,
  setCurrentCart,
  selectedPass,
}) => {
  const { token: cartToken } = currentCart
  const [showNewMemberModal, setShowNewMemberModal] = useState(false)
  const [initialMember, setInitialMember] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const members = currentCart.individualLineItems.filter(li => !li.fee)
  const updateCartContact = (cart, contact) => {
    // the cart needs a contact, fill with primary member details
    const { firstName, lastName, email, phone, postalCode } = contact
    const cartWithContact = {
      ...cart,
      firstName,
      lastName,
      email,
      phone,
      postalCode,
    }
    return api.updateCart({
      webstore,
      cartToken,
      ...cartWithContact,
    })
  }

  const addMember = (member) => {
    return new Promise((resolve, reject) => {
      api
        .addMember({
          webstore,
          cartToken,
          member,
        })
        .then((cart) => {
          if (member.primary) {
            updateCartContact(cart, member).then((cartWithContact) => {
              setCurrentCart(cartWithContact)
              resolve()
            })
          } else {
            setCurrentCart(cart)
            resolve()
          }
        })
        .catch((error) => {
          console.log(error)
          reject()
        })
    })
  }

  const removeMember = (member) => {
    return new Promise((resolve, reject) => {
      api
        .removeMember({
          webstore,
          cartToken,
          lineItemId: member.id,
        })
        .then((cart) => {
          setCurrentCart(cart)
          resolve(cart.individualLineItems.filter(li => !li.fee))
        })
        .catch((error) => {
          console.log(error)
          reject()
        })
    })
  }

  const removeMemberAndCheckPrimary = (member) => {
    setIsLoading(true)
    removeMember(member).then((updatedMembers) => {
      setIsLoading(false)
      const removedMember = member
      // If the primary member was removed,
      // update the new first member (if there is one) to be the primary
      if (removedMember.primary && !isEmpty(updatedMembers)) {
        setIsLoading(true)
        updateMember({ ...first(updatedMembers), primary: true }).then(() =>
          setIsLoading(false)
        )
      }
    })
  }

  const updateMember = (member) => {
    return new Promise((resolve, reject) => {
      api
        .updateMember({
          webstore,
          cartToken,
          ...member,
        })
        .then((cart) => {
          if (member.primary) {
            updateCartContact(cart, member).then((cartWithContact) => {
              setCurrentCart(cartWithContact)
              resolve()
            })
          } else {
            setCurrentCart(cart)
            resolve()
          }
        })
        .catch((error) => {
          console.log(error)
          reject()
        })
    })
  }

  const admissionLineItems = currentCart.lineItems.filter(li => !li.fee)

  return (
    <div>
      <h2>Season Pass Details</h2>
      <div className="checkout-container season-pass-details">
        <PassTicketPicker
          tickets={selectedPass.tickets}
          webstore={webstore}
          maxTickets={currentCart.maxTickets}
          incrementTicket={(ticket) => {
            setInitialMember(ticket)
            setShowNewMemberModal(true)
          }}
          decrementTicket={(webProductId) => {
            const lastMember = findLast(members, { webProductId })
            if (lastMember) removeMemberAndCheckPrimary(lastMember)
          }}
          ticketQuantities={ticketQuantityMap(admissionLineItems)}
          currentCart={currentCart}
          isSeasonPass
        />
      </div>
      {!isEmpty(members) && (
        <Members
          members={members}
          updateMember={updateMember}
          removeMember={removeMemberAndCheckPrimary}
          isLoading={isLoading}
        />
      )}
      {showNewMemberModal && (
        <MemberModal
          handleClose={() => setShowNewMemberModal(false)}
          initialMember={{
            ...initialMember,
            primary: isEmpty(members),
            firstName: '',
            lastName: '',
            postalCode: '',
            phone: '',
            email: '',
          }}
          saveMember={addMember}
        />
      )}
    </div>
  )
}

SeasonPassDetails.propTypes = propTypes
SeasonPassDetails.defaultProps = defaultProps

export default SeasonPassDetails
