import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'lp-components'
import { MemberForm } from 'forms'

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  initialMember: PropTypes.object.isRequired,
  saveMember: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
}

const defaultProps = {
  isEditing: false,
}

function MemberModal({ handleClose, initialMember, saveMember, isEditing }) {
  const headingText = isEditing ? 'Edit Member' : 'Add New Member'
  return (
    <Modal onClose={handleClose}>
      <h2>{headingText}</h2>
      {initialMember.primary && (
        <p>The first member will be the Primary Member on the Season Pass.</p>
      )}
      <MemberForm
        initialValues={initialMember}
        saveMember={(member) => {
          saveMember(member).then(() => handleClose())
        }}
      />
    </Modal>
  )
}

MemberModal.propTypes = propTypes
MemberModal.defaultProps = defaultProps

export default React.memo(MemberModal)
