import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
    geocodeByAddress,
} from 'react-places-autocomplete';
import { Input } from 'components';

const propTypes = {
    initialValues: PropTypes.string,
    setFullAddress: PropTypes.func.isRequired,
    updateCustomerInformation: PropTypes.func.isRequired,
}

function LocationSearchInput({
    initialValue,
    setFullAddress,
    updateCustomerInformation
}) {
    const [address, setAddress] = useState(initialValue !== undefined ? initialValue : '');
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false); 

    useEffect(() => {
        if(window.google) {
            setGoogleApiLoaded(true);
        }
    }, [window.google]);

    useEffect(() => {
        if (initialValue !== undefined && initialValue !== address) {
            setAddress(initialValue);
        }
    }, [initialValue]);

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        let streetNumber = '';
        let streetName = '';
        let city = '';
        let state = '';
        let postalCode = '';
        let country = '';
        results[0].address_components.forEach((component) => {
            if (component.types.some((c) => c.includes('street_number'))) {
              streetNumber = component.long_name;
            }
            if (component.types.some((c) => c.includes('route'))) {
              streetName = component.long_name;
            }
            if (component.types.some((c) => c.includes('locality'))) {
              city = component.long_name;
            }
            if (
              component.types.some((c) =>
                c.includes('administrative_area_level_1')
              )
            ) {
              state = component.short_name;
            }
            if (
              component.types.some(
                (c) => c.includes('postal_code') && !c.includes('suffix')
              )
            ) {
              postalCode = component.long_name;
            }
            if(component.types.some((c) => c.includes('country'))){
              country = component.short_name;
            }
        });  
        setFullAddress({streetNumber, streetName, city, state, postalCode, country});
    };

    const handleBlur = () => {
        updateCustomerInformation({addressLine1 : address})
    };

    return (
        <div className="places-auto-complete-wrapper" onBlur={handleBlur}>
            {googleApiLoaded && (
                <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    searchOptions={{ types: ['address'] }}
                    debounce={300}
                    
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <Input
                                required
                                label="Address Line 1"
                                name={"addressLine1"}
                                pattern=".*\S+.*"
                                {...getInputProps({
                                    className: 'location-search-input',
                                })}
                            />
                            {suggestions.length > 0 ? (
                                <div className='places-auto-complete-dropdown-wrapper'>
                                    {loading ? <div>loading...</div> : null}
                                    <div>
                                        {suggestions.map((suggestion) => (
                                            <div
                                                className={
                                                    suggestion.active
                                                        ? 'active-option google-option'
                                                        : 'google-option'
                                                }
                                                {...getSuggestionItemProps(suggestion)}
                                                key={suggestion.description}
                                                style={{
                                                    background: suggestion.active
                                                        ? 'rgba(166, 168, 221, 0.15)'
                                                        : '',
                                                }}
                                            >
                                                {suggestion.description}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )}
                </PlacesAutocomplete>
            )}
        </div>
    );
}


LocationSearchInput.propTypes = propTypes
export default React.memo(LocationSearchInput);