export const emailValidation = (email) => {
    let isValid = false;
    let errorMessage;
    
    if(!email){
      errorMessage= "Required";
    } else if(!isBlackListEmail(email.toLowerCase())){
      isValid= true
    } else {
      errorMessage= "Invalid Email"
    }
  
    return {
      isValid: isValid,
      errorMessage: errorMessage
    }
  
  }
  
export const postalCodeValidation = (postalCode) => {
    let isValid = false;
    let errorMessage;
  
    if(!postalCode){
      errorMessage= "Required";
    } else {
      isValid= true
    }
  
    return {
      isValid: isValid,
      errorMessage: errorMessage
    }
  }
  
export  const phoneValidation = (phoneNumber) => {
    let isValid = false;
    let errorMessage;
  
    if(!phoneNumber){
      errorMessage= "Required";
    } else {
      isValid= true
    }
  
    return {
      isValid: isValid,
      errorMessage: errorMessage
    }
  }

export  const passValidation = (pass) => {
    let isValid = false;
    let errorMessage;
  
    if(!pass || pass === ''){
      errorMessage= "Required";
    } else if(pass.replace(/\D/g,'').length === 15) {
      isValid= true
    } else {
      errorMessage= "Invalid Pass";
    }
  
    return {
      isValid: isValid,
      errorMessage: errorMessage
    }
  }


  const isBlackListEmail = (email) => {
    // Regular expression pattern to match email addresses
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

    const blacklisted_emails = process.env.BLACKLIST_EMAIL_ADDRESS?.split(",")
    const blacklisted_domains = process.env.BLACKLIST_EMAIL_DOMAIN?.split(",")

    if(!isValidEmail(email)){
      return true
    }

    const domain = email.match(emailRegex)[0]?.split('@')[1];

    if(blacklisted_emails.includes(email) || blacklisted_domains.includes(domain)){
      return true
    }
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }



export default function validation(){
  // Nothing here, just fix build error
}