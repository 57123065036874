export const TICKET_TYPES = {
  ADULT: 'adult',
  CHILD: 'child',
  PREMIER: 'Premier',
  STANDARD: 'Standard',
  PASS: 'Pass',
}

export const LAYOUT_TYPES = {
  CRUISE: 'cruise',
  PASS: 'pass',
  RESELLER: 'reseller',
  REDEMPTION: 'redemption'
}

export const PASS_TYPES = {
  TIMED_TICKET: 'timed_ticket',
  SEASON_PASS: 'season_pass',
}
