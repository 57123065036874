import React from 'react'
import PropTypes from 'prop-types'
import {
    Checkout,
    SeasonPassRedemption
  } from 'components'
  import { LAYOUT_TYPES } from 'config'
import { CheckoutProvider } from 'contexts/CheckoutContext'

const propTypes = {
    webstore: PropTypes.string.isRequired,
    webstoreName: PropTypes.string.isRequired,
    eventTypeId: PropTypes.number,
    eventId: PropTypes.number,
    startDate: PropTypes.string.isRequired,
    cart: PropTypes.object.isRequired,
    availableCruises: PropTypes.array.isRequired,
    termsLink: PropTypes.string,
    warningText: PropTypes.string,
    layoutType: PropTypes.string.isRequired,
    user: PropTypes.object,
    abandonedCartEnabled: PropTypes.bool,
  }

const defaultProps = {user: {}}


const ShoppingCart = (props) => {

    return (
      <CheckoutProvider value={props}>
        <div>
            {props.layoutType === LAYOUT_TYPES.REDEMPTION ?
                <SeasonPassRedemption />
              :
                <Checkout />
            }

        </div>
      </CheckoutProvider>
    )
  }


ShoppingCart.propTypes = propTypes
ShoppingCart.defaultProps = defaultProps
export default ShoppingCart
