import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Input, CountrySelect, LocationSearchInput } from 'components'
import PropTypes from 'prop-types'
import { emailValidation, postalCodeValidation, phoneValidation } from 'utils/validation';

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  setFullAddress: PropTypes.func.isRequired,
  hideCountryAndZip: PropTypes.bool,
  isCardHolderForm: PropTypes.bool
}

const CustomerSchema = Yup.object().shape({
  firstName: Yup.string().required('Required').trim(),
  lastName: Yup.string().required('Required').trim(),
  email: Yup.string().email('Invalid email').trim().test("validation-email", function (email) {
    const validation = emailValidation(email);
    if (!validation.isValid) {
      return this.createError({
        path: this.path,
        message: validation.errorMessage,
      });
    }
    return true
  }),
  phone: Yup.string().trim().test("validation-phone-number", function (phoneNumber) {
    const validation = phoneValidation(phoneNumber);
    if (!validation.isValid) {
      return this.createError({
        path: this.path,
        message: validation.errorMessage,
      });
    }
    return true
  }),
  addressLine1: Yup.string().required('Required').trim(),
  city: Yup.string().required('Required').trim(),
  state: Yup.string().required('Required').trim(),
  country: Yup.string().required('Required').trim(),
  postalCode: Yup.string().trim().test("validation-postal-code", function (postalCode) {
    const validation = postalCodeValidation(postalCode);
    if (!validation.isValid) {
      return this.createError({
        path: this.path,
        message: validation.errorMessage,
      });
    }
    return true
  }),
})

const CustomerForm = ({
  initialValues,
  updateCustomerInformation,
  hideCountryAndZip,
  isCardHolderForm = false,
  setFullAddress
}) => {
  return (
    <Formik initialValues={initialValues} validationSchema={CustomerSchema} enableReinitialize={true} >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <form id={!isCardHolderForm ? "customer-form" : "card-holder-form"}>
          <h2>{!isCardHolderForm ? "Customer Information" : "Card Holder Information"}</h2>
          <div className="checkout-container">
            <div className="input-field">
              {/* <label htmlFor="firstName">Full Name</label> */}
              <div className="input-container">
                <div className="input-container-half">
                  <Input
                    required
                    label="First Name"
                    name={!isCardHolderForm ? "firstName" : "cardHolderFirstName"}
                    pattern=".*\S+.*"
                    onBlur={(e) =>
                      e.target.value &&
                      updateCustomerInformation({
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-container-half">
                  <Input
                    required
                    label="Last Name"
                    name={!isCardHolderForm ? "lastName" : "cardHolderLastName"}
                    pattern=".*\S+.*"
                    onBlur={(e) =>
                      e.target.value &&
                      updateCustomerInformation({
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {!isCardHolderForm && (
              <div className="input-field">
                <Input
                  required
                  name="email"
                  onBlur={(e) =>
                    e.target.value &&
                    updateCustomerInformation({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {!isCardHolderForm && (
              <div className="input-field">
                <Input
                  required
                  name="phone"
                  pattern=".*\S+.*"
                  onBlur={(e) =>
                    e.target.value &&
                    updateCustomerInformation({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {!hideCountryAndZip && (
              <>
                <div className="input-field">
                  <LocationSearchInput 
                    initialValue={initialValues.addressLine1} 
                    setFullAddress={setFullAddress}
                    updateCustomerInformation={updateCustomerInformation}
                  />
                </div>
                <div className="input-field">
                  <Input
                    label="Address Line 2"
                    name={"addressLine2"}
                    onBlur={(e) =>
                      e.target.value &&
                      updateCustomerInformation({
                        [e.target.name]: e.target.value,
                      })
                    } 
                  />
                </div>
                <div className="input-field">
                  <div className="input-container">
                    <div className="input-container-half">
                      <Input
                        required
                        name="city"
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-container-half">
                      <Input
                        required
                        name="state"
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-container">
                    <div className="input-container-half">
                      <CountrySelect
                        name={'cardHolderCountry'}
                        updateCustomerInformation={updateCustomerInformation}
                        initialValue={!isCardHolderForm ? initialValues.country : initialValues.cardHolderCountry}
                        isCardHolderForm={isCardHolderForm}
                      />
                    </div>
                    <div className="input-container-half">
                      <Input
                        required
                        label="Postal Code"
                        name={!isCardHolderForm ? "postalCode" : "cardHolderPostalCode"}
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}
CustomerForm.propTypes = propTypes
export default CustomerForm
