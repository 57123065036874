import React from 'react'
import { Button } from 'lp-components'
import PropTypes from 'prop-types'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
}

const SubmitOrder = ({ onSubmit, pendingRequest }) => {
  return (
    <div className="submit-order-container">
      <Button
        onClick={onSubmit}
        style="primary"
        className="submit-order-button"
        disabled={pendingRequest}
      >
        Submit Order
      </Button>
    </div>
  )
}
SubmitOrder.propTypes = propTypes
export default SubmitOrder
