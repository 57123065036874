import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Input from './Input'
import PropTypes from 'prop-types'

// https://stackoverflow.com/questions/45940726/populate-react-select-with-image

const propTypes = {
  editedCruise: PropTypes.object.isRequired,
  availableCruises: PropTypes.array.isRequired,
  changeCruise: PropTypes.func.isRequired,
  resetLineItemsOnChange: PropTypes.func.isRequired,
  selectNewPass: PropTypes.func
}

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#f3f3f3',
    borderWidth: '2px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#f3f3f3',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '42px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#2b2b2b',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: '5px',
  }),
}

const CrusieSelect = ({
  editedCruise,
  availableCruises,
  changeCruise,
  resetLineItemsOnChange,
  selectNewPass
}) => {
  const getCurrentCruise = () => {
    let currentCruise

    availableCruises.forEach((cruise) => {
      if (cruise.eventTypeId === editedCruise.eventTypeId) {
        currentCruise = cruise;
      }
    });

    return currentCruise;
  }


  const findSelectedCruiseId = (eventTypeId) => {
    const selectedCruise = availableCruises.find(cruise => cruise.eventTypeId === eventTypeId);
    if(selectNewPass && selectedCruise){
      selectNewPass(selectedCruise.id);
    }
  } 

  const cruiseLabel = (cruise) => {
    return (
      <div className="cruise-label">
        <div className="title">
          <img src={cruise.image} />
          <p bablic-exclude="true">{cruise.displayName}</p>
        </div>
        <div className="info">
          <p className="location">
            {cruise.location}
          </p>
          {
            cruise.durationTimeDisplay &&
            <React.Fragment>
              <p>|</p>
              <p className="duration">
                {cruise.durationTimeDisplay}
              </p>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }

  const currentCruiseLabel = () => {
    const currentCruise = getCurrentCruise();
    if (typeof currentCruise === 'undefined') {
      return '';
    } else {
      return cruiseLabel(currentCruise);
    }
  }

  const cruiseOptions = availableCruises.map((cruise) => {
    return {
      value: cruise.eventTypeId,
      label: cruiseLabel(cruise)
    };
  });

  return (
    <React.Fragment>
      <Select
        onChange={(event) => {
          changeCruise(event.value);
          findSelectedCruiseId(event.value)
          resetLineItemsOnChange(editedCruise.eventTypeId);
        }}
        options={cruiseOptions}
        styles={customSelectStyles}
        className="cruise-dropdown-override"
        value={{
          value: editedCruise.eventTypeId,
          label: currentCruiseLabel()
        }}
      />
    </React.Fragment>
  )
}

CrusieSelect.propTypes = propTypes
export default CrusieSelect
