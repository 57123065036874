
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'

const propTypes = {
  passDetails: PropTypes.object,
  selectedCruise: PropTypes.object,
  availableCruises: PropTypes.array.isRequired,
  setSelectedCruise: PropTypes.func.isRequired,
}

const RedemptionCruiseDetails = ({ availableCruises, selectedCruise, setSelectedCruise }) => {
  return (
    <div className='customer-cruise-details'>
       <h2>Select Cruise</h2>
      <div className='pass-type-card-container'>
        <div className="card-content-wrapper">
          <div className={'pass-type-selector'}>
            <div className="card-wrapper">
              {availableCruises.map((cruise) => (
                <button
                  type="button"
                  key={cruise.id}
                  onClick={() => { setSelectedCruise(cruise) }}
                  className={classnames('pass-type-card grey-background', {
                    active: selectedCruise?.id === cruise.id,
                  })}
                >
                  <div className="card-content-wrapper">
                    <p className={'pass-type-card-title'}>
                      {cruise.displayName}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RedemptionCruiseDetails.propTypes = propTypes;
export default RedemptionCruiseDetails;