import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CustomerForm } from 'forms'
import { Receipt, BoardingPassDetails, TermsAndConditions } from 'components'
import { PaymentForm, CouponForm } from 'forms'
import * as api from 'api'
import { ticketQuantityMap } from 'utils'

const propTypes = {
  event: PropTypes.object,
  fetchEventsForDatepickerOnClick: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  currentCart: PropTypes.object.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  updateCardHolderInformation: PropTypes.func.isRequired,
  editCruiseDetails: PropTypes.func.isRequired,
  editedCruise: PropTypes.object,
  changeCruise: PropTypes.func.isRequired,
  includedDates: PropTypes.array.isRequired,
  initialDatePickerLoading: PropTypes.bool.isRequired,
  convertDatePickerValue: PropTypes.func.isRequired,
  hasCruiseTimes: PropTypes.bool.isRequired,
  standardCruiseTimes: PropTypes.array,
  premierCruiseTimes: PropTypes.array,
  updateCartCalendarEvent: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnMonthChange: PropTypes.func.isRequired,
  datePickerLoading: PropTypes.bool.isRequired,
  selectedPass: PropTypes.object.isRequired,
  availableCruises: PropTypes.array.isRequired,
  showResellerCheckout: PropTypes.bool.isRequired,
  setShowResellerCheckout: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  setPendingRequest: PropTypes.func.isRequired,
  termsLink: PropTypes.string.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  updateCartCoupon: PropTypes.func.isRequired,
  hasItems: PropTypes.bool.isRequired,
  validateRequiredData: PropTypes.func.isRequired,
  emailField: PropTypes.object.isRequired,
  selectNewPass: PropTypes.func,
  setFullAddress: PropTypes.func.isRequired,
  selectedEventId: PropTypes.number.isRequired
}

const defaultProps = {}

function TimedTicketResellerFlow({
  event,
  fetchEventsForDatepickerOnClick,
  webstore,
  currentCart,
  setCurrentCart,
  customer,
  updateCustomerInformation,
  updateCardHolderInformation,
  editCruiseDetails,
  editedCruise,
  changeCruise,
  includedDates,
  initialDatePickerLoading,
  convertDatePickerValue,
  hasCruiseTimes,
  standardCruiseTimes,
  premierCruiseTimes,
  selectedDate,
  updateCartCalendarEvent,
  incrementTicket,
  decrementTicket,
  waitingForIncrementOrDecrementResponse,
  fetchEventsForDatepickerOnMonthChange,
  datePickerLoading,
  selectedPass,
  availableCruises,
  showResellerCheckout,
  setShowResellerCheckout,
  handleError,
  setPendingRequest,
  pendingRequest,
  termsLink,
  validateCoupon,
  updateCartCoupon,
  hasItems,
  validateRequiredData,
  emailField,
  selectNewPass,
  setFullAddress,
  selectedEventId
}) {
  const { token: cartToken } = currentCart
  const [showTicketSelector, setShowTicketSelector] = useState(false)

  useEffect(() => {
    if(selectedEventId){
      setShowTicketSelector(true)
    }
  }, [selectedEventId])

  const saveAutofilledCustomerData = () => {
    updateCustomerInformation({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
    })
  }

  useEffect(() => {
    changeCruise(selectedPass.eventTypeId)
    fetchEventsForDatepickerOnClick(selectedPass)
    resetLineItemsOnChange(selectedPass.eventTypeId)
    saveAutofilledCustomerData()
  }, [selectedPass])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [showResellerCheckout])


  const resetLineItemsOnChange = (eventTypeId, eventStart) => {
    api
      .updateCart({
        webstore,
        cartToken,
        eventTypeId: eventTypeId,
        eventStart: eventStart,
      })
      .then((response) => {
        setCurrentCart(response)
        setShowTicketSelector(false)
      })
      .catch((error) => console.log(error))
  }

  const admissionLineItems = currentCart.lineItems.filter(li => !li.fee)

  const proceedToResellerCheckout = () => {
    const customerFormValid = document.getElementById('customer-form')
                                          .checkValidity()
    emailField.isValid({ email: email.value }).then(function (emailValid) {
      if (!customerFormValid) {
        handleError(
          'Oops! Missing Customer Information',
          'Please complete the customer information section.'
        )
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      } else if (!emailValid) {
        handleError(
          'Oops! Invalid email',
          'Please provide a valid email address.'
        )
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      } else {
        setShowResellerCheckout(true)
      }
    })
  }


  if (showResellerCheckout) {
    const { merchantAccountId } = currentCart
    return (
      <React.Fragment>
        <Receipt
          lineItems={currentCart.lineItems}
          taxes={currentCart.taxes}
          discounts={currentCart.discounts}
          couponDetails={currentCart.couponDetails}
          removeCoupon={() => updateCartCoupon('')}
          total={currentCart.total}
          cruiseDisplayName={
            event ? event.displayName : currentCart.cruiseDisplayName
          }
          startDateTime={event ? event.startDateTime : currentCart.startDateTime}
        />

        <CouponForm onSubmit={validateCoupon} />

        {/** Added for cardholder first, last, & country */}
        <CustomerForm
          initialValues={{ ...customer }}
          updateCustomerInformation={updateCardHolderInformation}
          setFullAddress={setFullAddress}
          isCardHolderForm
        />

        <PaymentForm
          webstore={webstore}
          fetchPaymentIframe={() =>
            api.fetchPaymentIframe({ webstore, merchantAccountId, cartId: currentCart.id })
          }
          validateRequiredData={validateRequiredData}
          handleError={handleError}
          pendingRequest={pendingRequest}
          currentCart={currentCart}
        />

        <TermsAndConditions
          termsLink={termsLink}
          verifyRecaptcha={api.verifyRecaptcha}
        />
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <React.Fragment>
          <CustomerForm
            initialValues={{ ...customer }}
            updateCustomerInformation={updateCustomerInformation}
            setFullAddress={setFullAddress}
            hideCountryAndZip
          />
        </React.Fragment>

        <BoardingPassDetails
          editCruiseDetails={editCruiseDetails}
          webstore={webstore}
          editedCruise={editedCruise}
          includedDates={includedDates}
          initialDatePickerLoading={initialDatePickerLoading}
          convertDatePickerValue={convertDatePickerValue}
          hasCruiseTimes={hasCruiseTimes}
          standardCruiseTimes={standardCruiseTimes}
          premierCruiseTimes={premierCruiseTimes}
          updateCartCalendarEvent={updateCartCalendarEvent}
          tickets={event ? event.tickets : []}
          maxTickets={currentCart.maxTickets}
          selectedDate={selectedDate}
          incrementTicket={incrementTicket}
          decrementTicket={decrementTicket}
          waitingForIncrementOrDecrementResponse={
            waitingForIncrementOrDecrementResponse
          }
          ticketQuantities={ticketQuantityMap(admissionLineItems)}
          lineItems={currentCart.lineItems}
          resetLineItemsOnChange={resetLineItemsOnChange}
          fetchEventsForDatepickerOnMonthChange={
            fetchEventsForDatepickerOnMonthChange
          }
          datePickerLoading={datePickerLoading}
          showTicketSelector={showTicketSelector}
          setShowTicketSelector={setShowTicketSelector}
          showCruiseSelect
          availableCruises={availableCruises}
          changeCruise={changeCruise}
          selectNewPass={selectNewPass}
          currentCart={currentCart}
          isReseller={true}
        />

        <Receipt
          lineItems={currentCart.lineItems}
          taxes={currentCart.taxes}
          total={currentCart.total}
          resellerCheckoutFlow
        />
        {hasItems && (
          <div className="button-container">
            <button onClick={proceedToResellerCheckout} className="button-primary">
              Proceed to Checkout
            </button>
          </div>
        )}
      </React.Fragment>
    )
  }
}

TimedTicketResellerFlow.propTypes = propTypes
TimedTicketResellerFlow.defaultProps = defaultProps

export default React.memo(TimedTicketResellerFlow)
