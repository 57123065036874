import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.string,
}

const defaultProps = {
  children: '',
}

function RenderedHtml({ children }) {
  return (
    <div
      className="rendered-html"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

RenderedHtml.propTypes = propTypes
RenderedHtml.defaultProps = defaultProps

export default pure(RenderedHtml)
