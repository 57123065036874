import React, { useState, useEffect } from 'react'
import { useCheckout }  from 'hooks/useCheckout'
import { SeasonPassRedemptionPageTwo, SeasonPassRedemptionPageOne } from 'components'
import sslCheckoutImage from 'sslcheckout.svg'
import moment from 'moment-timezone'
import {
  getCruises,
  fetchEventByTypeAndDates,
  cleanEventWithCruiseChanges
} from 'utils/redepmtionUtil';
import swal from '@sweetalert/with-react';
import * as api from 'api';

const SeasonPassRedemption = () => {

  const { webstore, availableCruises, termsLink, warningText, cart, abandonedCartEnabled } = useCheckout();

  const [isValid, setIsValid] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceCache, setServiceCache] = useState(new Map());
  const [passDetails, setPassDetails] = useState({});
  const [selectedCruise, setSelectedCruise] = useState();
  const [events, setEvents] = useState({ initialDatePickerLoading: true });
  const [selectedDate, setSelectedDate] = useState();
  const [inCalendarLoading, setCalendarLoading] = useState(false);
  const [includedDates, setIncludedDates] = useState();
  const [hasCruiseTimes, setHasCruiseTimes] = useState(false);
  const [standardCruiseTimes, setStandardCruiseTimes] = useState([]);
  const [premierCruiseTimes, setPremierCruiseTimes] = useState([]);
  const [, setShowTicketSelector] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState();
  const [currentCart,] = useState(cart)

  const { token: cartToken, merchantAccountId } = currentCart;

  async function updateCartInformation(data = {}) {
    await api.updateCart({
      webstore,
      cartToken,
      ...data,
    })
  }

  function trackAbandonedCart() {
    return api.trackAbandonedCart(webstore, cart.id)
  }


  useEffect(() => {
    // set ENV HUBSPOT_ABANDONED_CART_ENABLED to true
    // once NYCL is ready to enable abandoned cart on production
    if (!abandonedCartEnabled) return

    if (cart.previousSelectionsUnavailable) {
      swal({
        title: "Oh no!",
        content: <UnavailableSelectionsMessage/>,
      })
    }

    window.addEventListener('beforeunload', trackAbandonedCart)

    return () => {
      window.removeEventListener('beforeunload', trackAbandonedCart)
    }
  }, []);

  useEffect(async () => {
    if (selectedCruise) {
      await updateCartInformation({
        event_type_id: selectedCruise.eventTypeId,
        event_name: selectedCruise.displayName,
      })
      await fetchEventByTypeAndDates(
        selectedCruise,
        webstore,
        setServiceCache,
        serviceCache,
        setIncludedDates,
        setEvents,
        undefined,
        undefined
      )
    } else {
      cleanEventWithCruiseChanges(setServiceCache, setEvents)
    }
  }, [selectedCruise]);

  const handleNavButtonClick = (value) => {
    setCurrentPage(value);
    if (value === 1) {
      setSelectedDate();
      setHasCruiseTimes(false);
      setSelectedEventId();
    }
  }

  const fetchEventsForDatepickerOnMonthChange = async (lastDate) => {
    setCalendarLoading(true);
    await fetchEventByTypeAndDates(
      selectedCruise,
      webstore,
      setServiceCache,
      serviceCache,
      setIncludedDates,
      setEvents,
      includedDates,
      lastDate
    );
    setCalendarLoading(false);
  }

  const onDateChange = async (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const dayEvents = events.filter(event => event.startDate === formattedDate);
    const { standardCruises, premierCruises } = getCruises(dayEvents);
    setStandardCruiseTimes(standardCruises);
    setPremierCruiseTimes(premierCruises);
    setSelectedDate(date);
    setHasCruiseTimes(true);
    await updateCartInformation({
      eventStart: date
    })
  }


  const updateCartCalendarEvent = async (selectedEvent) => {
    if(selectedEvent.id !== selectedEventId) {
      const ticketResponse = await api.fetchEvent({
        webstore,
        eventId: selectedEvent?.id
      });
      await updateCartInformation({
        eventStart: selectedEvent?.startDateTime,
        eventEnd: selectedEvent?.endDateTime,
        cruiseId: selectedEvent?.cruiseId,
        eventId: selectedEvent?.id,
        resourceId: selectedEvent?.resourceId
      })
      await api.incrementTicket({
        webstore,
        cartToken,
        ticket: {
          ...ticketResponse?.tickets[0],
        }
      })
    } 
    setSelectedEventId(selectedEvent.id)
  }

  const handleError = (
    title = 'Oops!',
    text = 'Unable to process your order.'
  ) => {
    swal({
      title: title,
      text: text,
    })
  }

  const submitOrder = () => {
    const termsAndConditions = document.getElementById('terms-checkbox')
    const recaptchaComplete =
      document.getElementById('recap-response').value === 'true'

    if (termsAndConditions && !termsAndConditions.checked) {
      handleError('Oops!', 'Please accept the terms and conditions.')
    } else if (!recaptchaComplete) {
      handleError('Oops!', 'Please complete the reCAPTCHA.')
    } else {
      processOrder({
        cartToken: cartToken
      })
    }
  }


  const processOrder = (orderParams) => {
    // 1. initialize order (and place a hold for timed tickets)
    // 3. submit order to egalaxy api (then captures the charge)
    // 4. order complete, redirect to confirmation page
    api
      .initializeOrder({ webstore, ...orderParams })
      .then((response) => {
        return api.submitOrder({
          webstore,
          ...orderParams,
          orderToken: response.token,
        })
      })
      .then((response) => {
        return (window.location = `/${webstore}/confirmation?orderToken=${response.token}`)
      })
      .catch((error) => {
        if (error.errors.message.indexOf("not available") !== -1) {
          handleError('', 'Your ticket request exceeds available capacity. Please try again with fewer tickets or select a different date or time');
        } else {
          handleError('Oops!', error.errors.message)
        }
      })
  }


  return (
    <>
      <div className="checkout-header">
        <h1>
          Redeem Tickets
        </h1>
        <img src={sslCheckoutImage} alt="SSL secure checkout" />
      </div>
      {/* Page 1 */}
      {currentPage === 1 && (
        <SeasonPassRedemptionPageOne {...{
            webstore,
            currentPage,
            passDetails,
            setPassDetails, 
            isValid,
            setIsValid, 
            selectedCruise,
            setSelectedCruise,
            handleNavButtonClick,
            updateCartInformation,
            availableCruises
          }} 
        />
      )} 
      {(currentPage === 2 && isValid) && (
        <SeasonPassRedemptionPageTwo {...{
            currentPage,
            passDetails,
            selectedCruise,
            handleNavButtonClick,
            events,
            selectedDate,
            onDateChange,
            includedDates,
            fetchEventsForDatepickerOnMonthChange,
            inCalendarLoading,
            hasCruiseTimes,
            premierCruiseTimes,
            selectedEventId,
            updateCartCalendarEvent,
            setShowTicketSelector,
            standardCruiseTimes,
            termsLink,
            warningText,
            submitOrder
          }}
        />
      )}
    </>
  )
}


export default SeasonPassRedemption;

