import * as api from 'api';
import swal from '@sweetalert/with-react'
import moment from 'moment-timezone'
import { getDefaultLocale } from 'react-datepicker';


  export const handleError = (
    title = 'Oops!',
    text = 'Not Valid SeasonPass.'
  ) => {
    swal({
      title: title,
      text: text,
    })
  } 
  
  export const createCacheKey= (webstore, eventTypeId, startDate, endDate) => {
    return `${webstore}-${eventTypeId}-${startDate}-${endDate}`;
  };
  
  export const getSeasonPassType = (custNo) => {
    switch(custNo) {
      case "La Barca":
        return "lbc";
      case "NRLCWeb":
        return "nrlc";
      default:
        return undefined
    }
  };
  
  export const fetchEventsService = async (webstore, eventTypeId, startDate, endDate) => {
    try {
      const eventsResponse = await api.fetchEvents({ 
        webstore, 
        startDate: startDate,
        endDate: endDate,
        eventTypeId,
        includePricing: false, 
      });
      return eventsResponse;
    } catch (e) {
      console.error(e);
      return {
        isError: true,
        error: e
      }
    }
  };
  
  export const getCruises = (response) => {
    const standardCruises = response.filter(
      (cruise) => !cruise.premierCruise
    )
    const premierCruises = response.filter((cruise) => cruise.premierCruise);
    return {
      standardCruises,
      premierCruises
    }
  };
  
  export const dedupEvents = (eventsArray) => {
    const uniqueObjects = eventsArray.reduce((uniqueMap, obj) => {
      if (!uniqueMap[obj.id]) {
        uniqueMap[obj.id] = obj;
      }
      return uniqueMap;
    }, {});
    
    return Object.values(uniqueObjects);
  };
  
  export const calculateUniqueEventDates = (eventResponse, includedDates) => {
    let eventDates = eventResponse?.map(
      (eventDate) => new Date(eventDate.startDateTime)
    )
    if(includedDates){
      eventDates = [...includedDates, ...eventDates];
    }
    const uniqDatesWithEvents = [...new Set(eventDates)];
    return uniqDatesWithEvents;
  };

  export default function redemptionUtil(){
    // Nothing here, just fix build error
  }


  export const getStartingDate = (startingDate, defaultStartDate) => {
    if(startingDate){
      return startingDate;
    } else if (defaultStartDate) {
      const date = moment(defaultStartDate);
      const today = moment(new Date()); 
      const difference = today.diff(date);
      if(difference > 0 ){
        return new Date();
      } else {
        return defaultStartDate;
      }
    } else {
      return new Date();
    }
  }

  export const fetchEventByTypeAndDates = async (
    selectedCruise, 
    webstore, 
    setServiceCache, 
    serviceCache, 
    setIncludedDates, 
    setEvents,
    includedDates,
    startingDate
  ) => {
    const serviceCacheMap = new Map(serviceCache);
    const date = getStartingDate(startingDate, selectedCruise.defaultStartDate);
    const eventTypeId = selectedCruise.eventTypeId
    const startDate = moment(date).tz('America/New_York').subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const endDate = moment(date).tz('America/New_York').add(2, 'months').endOf('month').format('YYYY-MM-DD')
    const key = createCacheKey(webstore, eventTypeId, startDate, endDate)
    if(!serviceCacheMap.get(key)){
      const response = await fetchEventsService(webstore, eventTypeId, startDate, endDate)
      if (response.isError !== true) {
        response.initialDatePickerLoading = false
        const uniqDatesWithEvents = calculateUniqueEventDates(response, includedDates)
        setIncludedDates(uniqDatesWithEvents)
        const uniqueEvents = dedupEvents(response);
        setEvents(uniqueEvents);
        serviceCacheMap.set(key, uniqueEvents);
        setServiceCache(serviceCacheMap);
      } else {
        handleError('Oops!', 'Unable to fetch Events')
      }
    } else {
      const uniqueEvents = serviceCacheMap.get(key);
      const uniqDatesWithEvents = calculateUniqueEventDates(uniqueEvents, includedDates)
      setIncludedDates(uniqDatesWithEvents)
      setEvents(uniqueEvents);
    }  
  }
  
  export const cleanEventWithCruiseChanges = async (setServiceCache, setIncludedDates) => {
    setServiceCache(new Map())
    setIncludedDates()
    setIncludedDates({ initialDatePickerLoading: true })
  }