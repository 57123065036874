import React from 'react'
import { wrapDisplayName } from 'recompose'
import { useField } from 'formik'

// Returns true if the argument is an event
// Copied from https://github.com/erikras/redux-form/blob/master/src/events/isEvent.js

function isEvent(value) {
  return !!(value && value.stopPropagation && value.preventDefault)
}

// Allow both the default formik onChange and a custom onChange to be called.
function composeChangeHandlers(fieldName, customOnChange, defaultOnChange) {
  return function onChange(value) {
    // Create a fake event if necessary since formik expects an event object
    const changeEvent = isEvent(value)
      ? value
      : { target: { name: fieldName, value } }
    defaultOnChange(changeEvent)
    // Call custom onChange handler
    if (customOnChange) customOnChange(changeEvent)
  }
}

// Adapts a redux-form input to use formik instead by passing the correct props.
function withFormikAdapter() {
  return (InputComponent) => {
    function WrappedInputComponent({ onChange, onBlur, ...rest }) {
      const [field, meta] = useField(rest)

      return (
        <InputComponent
          input={{
            name: field.name,
            value: field.value,
            onChange: composeChangeHandlers(
              field.name,
              onChange,
              field.onChange
            ),
            onBlur: composeChangeHandlers(field.name, onBlur, field.onBlur),
          }}
          meta={{
            error: meta.error,
            touched: meta.touched,
            invalid: !!meta.error,
          }}
          {...rest}
        />
      )
    }
    WrappedInputComponent.displayName = wrapDisplayName(
      InputComponent,
      'withFormikAdapter'
    )
    return WrappedInputComponent
  }
}

export default withFormikAdapter
