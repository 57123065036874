
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker'
import { Spinner } from 'lp-components'
import classnames from 'classnames'
import { DateInput, BoardingPassTimes } from 'components'
import TermsAndConditions from './TermsAndConditions';
import * as api from 'api';

const propTypes = {
    events: PropTypes.any,
    selectedDate: PropTypes.any,
    onDateChange: PropTypes.func,
    includedDates: PropTypes.array,
    fetchEventsForDatepickerOnMonthChange: PropTypes.func,
    inCalendarLoading: PropTypes.bool,
    hasCruiseTimes: PropTypes.bool,
    premierCruiseTimes: PropTypes.array,
    selectedEventId: PropTypes.number,
    updateCartCalendarEvent: PropTypes.func,
    setShowTicketSelector: PropTypes.func,
    standardCruiseTimes: PropTypes.array,
    termsLink: PropTypes.string,
    warningText: PropTypes.string,
}

const RedemptionBoardingPass = ({
    events,
    selectedDate,
    onDateChange,
    includedDates,
    fetchEventsForDatepickerOnMonthChange,
    inCalendarLoading,
    hasCruiseTimes,
    premierCruiseTimes,
    selectedEventId,
    updateCartCalendarEvent,
    setShowTicketSelector,
    standardCruiseTimes,
    termsLink,
    warningText
}) => {
    const [loadingNewPrice, setLoadingNewPrice] = useState(false);
    return (
      <>
        <h2>BOARDING PASS DETAILS</h2>  
        <div className='boarding-pass'>
            {events.initialDatePickerLoading ? (
              <Spinner />
            ) : (
              <DatePicker
                placeholderText="CHOOSE DATE"
                dateFormat="yyyy-MM-dd"
                selected={selectedDate}
                onChange={(date) => {
                  onDateChange(date);
                }}
                minDate={new Date()}
                includeDates={includedDates}
                popperPlacement="top"
                customInput={<DateInput />}
                onMonthChange={(lastDay) => {
                  fetchEventsForDatepickerOnMonthChange(lastDay)
                }}
                calendarClassName={classnames('datepicker', {
                  'in-progress': inCalendarLoading,
                })}
                className="boarding-pass-datepicker"
              />
            )}
            {hasCruiseTimes && (
              <div>
                {premierCruiseTimes?.length > 0 && (
                  <BoardingPassTimes
                    eventTimes={premierCruiseTimes}
                    selectedEventId={selectedEventId}
                    updateCartCalendarEvent={updateCartCalendarEvent}
                    loadingNewPrice={loadingNewPrice}
                    setLoadingNewPrice={setLoadingNewPrice}
                    setShowTicketSelector={setShowTicketSelector}
                  />
                )}
                {standardCruiseTimes?.length > 0 && (
                  <BoardingPassTimes
                    eventTimes={standardCruiseTimes}
                    selectedEventId={selectedEventId}
                    updateCartCalendarEvent={updateCartCalendarEvent}
                    loadingNewPrice={loadingNewPrice}
                    setLoadingNewPrice={setLoadingNewPrice}
                    setShowTicketSelector={setShowTicketSelector}
                  />
                )}
              </div>
            )}
        </div>
        {hasCruiseTimes && (
            <TermsAndConditions
              termsLink={termsLink}
              verifyRecaptcha={api.verifyRecaptcha}
              warningText={warningText}
            />
        )}
      </>
    )
}

RedemptionBoardingPass.propTypes = propTypes;
export default RedemptionBoardingPass;