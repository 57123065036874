import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { countryList } from 'utils'
import Input from './Input'
import PropTypes from 'prop-types'

const propTypes = {
  updateCustomerInformation: PropTypes.func,
  initialValue: PropTypes.string,
  isCardHolderForm: PropTypes.bool
}

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#f3f3f3',
    borderWidth: '2px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#f3f3f3',
    width: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '42px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#2b2b2b',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: '5px',
  }),
}

const CountrySelect = ({ updateCustomerInformation, initialValue, isCardHolderForm = false }) => {
  const [initialCountry, setInitialCountry] = useState(null)

  useEffect(() => {
    if (initialValue) {
      const initialCountryFiltered = countryList.filter((country) => {
        return country.value === initialValue
      })
      setInitialCountry({
        value: initialValue,
        label: initialCountryFiltered[0].label,
      })
    }
  }, [initialValue])

  const [selectedCountry, setSelectedCountry] = useState(null)

  const initialCountryValue = () => {
    if (initialCountry && !selectedCountry) {
      return initialCountry.value
    } else if (selectedCountry) {
      return selectedCountry.value
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (selectedCountry) {
      const countryKey = !isCardHolderForm ? "country" : "cardHolderCountry";
      updateCustomerInformation({
        [countryKey]: selectedCountry.value,
      })
    }
  }, [selectedCountry])
  return (
    <React.Fragment>
      <Input
        required
        label="Country"
        name={!isCardHolderForm ? "country" : "cardHolderCountry"}
        hidden={true}
        value={initialCountryValue()}
      />
      <Select
        defaultValue={initialCountry}
        onChange={setSelectedCountry}
        options={countryList}
        styles={customSelectStyles}
        placeholder={initialCountry ? initialCountry.label : ''}
        className="country-select-container"
        classNamePrefix="country-select"
      />
    </React.Fragment>
  )
}
CountrySelect.propTypes = propTypes
export default CountrySelect
