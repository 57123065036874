import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Input, CountrySelect, LocationSearchInput } from 'components'
import PropTypes from 'prop-types'
import { emailValidation, postalCodeValidation, phoneValidation } from 'utils/validation';

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  setFullAddress: PropTypes.func.isRequired,
  hideCountryAndZip: PropTypes.bool,
  isCardHolderForm: PropTypes.bool
}

const AddressSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Required').trim(),
  city: Yup.string().required('Required').trim(),
  state: Yup.string().required('Required').trim(),
  country: Yup.string().required('Required').trim(),
  postalCode: Yup.string().trim().test("validation-postal-code", function (postalCode) {
    const validation = postalCodeValidation(postalCode);
    if (!validation.isValid) {
      return this.createError({
        path: this.path,
        message: validation.errorMessage,
      });
    }
    return true
  }),
})

const AddressForm = ({
  initialValues,
  updateCustomerInformation,
  setFullAddress
}) => {
  return (
    <Formik initialValues={initialValues} validationSchema={AddressSchema} enableReinitialize={true} >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <form id={"address-form"}>
          <h2>{"Address Information"}</h2>
          <div className="checkout-container">
                <div className="input-field">
                  <LocationSearchInput 
                    initialValue={initialValues.addressLine1} 
                    setFullAddress={setFullAddress}
                    updateCustomerInformation={updateCustomerInformation}
                  />
                </div>
                <div className="input-field">
                  <Input
                    label="Address Line 2"
                    name={"addressLine2"}
                    onBlur={(e) =>
                      e.target.value &&
                      updateCustomerInformation({
                        [e.target.name]: e.target.value,
                      })
                    } 
                  />
                </div>
                <div className="input-field">
                  <div className="input-container">
                    <div className="input-container-half">
                      <Input
                        required
                        name="city"
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-container-half">
                      <Input
                        required
                        name="state"
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-container">
                    <div className="input-container-half">
                      <CountrySelect
                        name={'country'}
                        updateCustomerInformation={updateCustomerInformation}
                        initialValue={initialValues.country}
                        isCardHolderForm={false}
                      />
                    </div>
                    <div className="input-container-half">
                      <Input
                        required
                        label="Postal Code"
                        name={"postalCode"}
                        pattern=".*\S+.*"
                        onBlur={(e) =>
                          e.target.value &&
                          updateCustomerInformation({
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

AddressForm.propTypes = propTypes
export default AddressForm
