export function sendPurchaseData(webstore, orderNumber, cart, lineItems, tracked = false) {
  window.dataLayer = window.dataLayer || []

  if (window.dataLayer && !tracked) {
    var purchaseData = {
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: orderNumber,
            affiliation: webstore + ' Galaxy Checkout',
            revenue: cart.productTotal, // excludes fee surcharge totals
            tax: cart.taxes,
            shipping: 0,
            coupon: cart.coupon,
          },
          products: lineItems, // excludes fee line items
        },
      },
    }
    dataLayer.push(purchaseData)
  }
}

export function sendAddToCartData(data, webstoreName, currentCart) {
  if (window.dataLayer) {
    const addToCartData = {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            {
              name: currentCart.eventName,
              id: data.plu,
              price: data.price,
              brand: webstoreName,
              category: currentCart.cruiseDisplayName,
              variant: `${currentCart.cruiseType || 'Standard' } - ${capitalize(data.ticketType)}`,
              quantity: '1',
            },
          ],
        },
      },
    }
    dataLayer.push(addToCartData)
  }
}

export function sendCheckoutData(cart) {
  const productData = cart.admissionLineItems.map((lineItem) => {
    return {
      name: cart.eventName,
      id: lineItem.plu,
      price: lineItem.price,
      brand: cart.merchantAccount,
      category: cart.cruiseDisplayName,
      variant: `${cart.cruiseType} - ${lineItem.displayName}`,
      quantity: '1',
    }
  })

  if (window.dataLayer) {
    const checkoutData = {
      event: 'checkout',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          products: productData,
        },
      },
    }
    dataLayer.push(checkoutData)
  }
}


export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
