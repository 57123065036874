import React, { useEffect, useState } from 'react'
import BoardingPassTimes from './BoardingPassTimes'
import PassTicketPicker from './PassTicketPicker'
import DatePicker from 'react-datepicker'
import DateInput from './DateInput'
import moment from 'moment-timezone'
import { Spinner } from 'lp-components'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CruiseSelect } from 'components'

const propTypes = {
  editCruiseDetails: PropTypes.func.isRequired,
  webstore:PropTypes.string.isRequired,
  editedCruise: PropTypes.object,
  includedDates: PropTypes.array.isRequired,
  initialDatePickerLoading: PropTypes.bool.isRequired,
  convertDatePickerValue: PropTypes.func.isRequired,
  hasCruiseTimes: PropTypes.bool.isRequired,
  standardCruiseTimes: PropTypes.array,
  premierCruiseTimes: PropTypes.array,
  selectedEventId: PropTypes.number,
  updateCartCalendarEvent: PropTypes.func.isRequired,
  tickets: PropTypes.array.isRequired,
  maxTickets: PropTypes.number.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool.isRequired,
  ticketQuantities: PropTypes.any,
  lineItems: PropTypes.array,
  resetLineItemsOnChange: PropTypes.func.isRequired,
  fetchEventsForDatepickerOnMonthChange: PropTypes.func.isRequired,
  datePickerLoading: PropTypes.bool.isRequired,
  showTicketSelector: PropTypes.bool.isRequired,
  setShowTicketSelector: PropTypes.func.isRequired,
  showCruiseSelect: PropTypes.bool,
  availableCruises: PropTypes.array,
  changeCruise: PropTypes.func,
  selectNewPass: PropTypes.func,
  currentCart: PropTypes.object.isRequired,
  isReseller: PropTypes.bool
}

const BoardingPassDetails = ({
  editCruiseDetails,
  webstore,
  editedCruise,
  includedDates,
  initialDatePickerLoading,
  convertDatePickerValue,
  hasCruiseTimes,
  standardCruiseTimes,
  premierCruiseTimes,
  selectedEventId,
  updateCartCalendarEvent,
  tickets,
  maxTickets,
  selectedDate,
  incrementTicket,
  decrementTicket,
  waitingForIncrementOrDecrementResponse,
  ticketQuantities,
  lineItems,
  resetLineItemsOnChange,
  fetchEventsForDatepickerOnMonthChange,
  datePickerLoading,
  showTicketSelector,
  setShowTicketSelector,
  showCruiseSelect,
  availableCruises,
  changeCruise,
  selectNewPass,
  currentCart,
  isReseller = false
}) => {
  const [loadingNewPrice, setLoadingNewPrice] = useState(false);

  useEffect(() => {
    if (editedCruise.startDate.length > 0) {
      editCruiseDetails('boardingPassDetails', editedCruise.eventTypeId, editedCruise.startDate)
    }
  }, [editedCruise.startDate])

  useEffect(() => {
    if (showTicketSelector || lineItems.length > 0) {
      setLoadingNewPrice(false);
    }
  }, [showTicketSelector, lineItems.length]);

  return (
    <div className="reseller-checkout">
      <h2>Boarding Pass Details</h2>
      <div className="checkout-container bst-date-section">
        {showCruiseSelect && (
          <CruiseSelect
            editedCruise={editedCruise}
            availableCruises={availableCruises}
            changeCruise={changeCruise}
            resetLineItemsOnChange={resetLineItemsOnChange}
            selectNewPass={selectNewPass}
          />
        )}
        {initialDatePickerLoading ? (
          <Spinner />
        ) : (
          <DatePicker
            placeholderText="CHOOSE DATE"
            dateFormat="yyyy-MM-dd"
            selected={selectedDate}
            onChange={(date) => {
              convertDatePickerValue(date)
              resetLineItemsOnChange(
                editedCruise.eventTypeId,
                moment(date).format()
              )
              setShowTicketSelector(false)
            }}
            minDate={new Date()}
            includeDates={includedDates}
            popperPlacement="top"
            customInput={<DateInput />}
            onMonthChange={(lastDay) => {
              fetchEventsForDatepickerOnMonthChange(lastDay)
            }}
            calendarClassName={classnames('datepicker', {
              'in-progress': datePickerLoading,
            })}
            className="boarding-pass-datepicker"
          />
        )}
        {hasCruiseTimes && (
          <div>
            {premierCruiseTimes.length > 0 && (
              <BoardingPassTimes
                eventTimes={premierCruiseTimes}
                selectedEventId={selectedEventId}
                updateCartCalendarEvent={updateCartCalendarEvent}
                setShowTicketSelector={setShowTicketSelector}
                loadingNewPrice={loadingNewPrice}
                setLoadingNewPrice={setLoadingNewPrice}
                isReseller={isReseller}
              />
            )}
            {standardCruiseTimes.length > 0 && (
              <BoardingPassTimes
                eventTimes={standardCruiseTimes}
                selectedEventId={selectedEventId}
                updateCartCalendarEvent={updateCartCalendarEvent}
                setShowTicketSelector={setShowTicketSelector}
                loadingNewPrice={loadingNewPrice}
                setLoadingNewPrice={setLoadingNewPrice}
                isReseller={isReseller}
              />
            )}
          </div>
        )}
        {(showTicketSelector || lineItems.length > 0) ? (
          <PassTicketPicker
            maxTickets={maxTickets}
            tickets={tickets}
            webstore={webstore}
            incrementTicket={incrementTicket}
            decrementTicket={decrementTicket}
            waitingForIncrementOrDecrementResponse={
              waitingForIncrementOrDecrementResponse
            }
            ticketQuantities={ticketQuantities}
            currentCart= {currentCart}
          />
        ) : loadingNewPrice ? (
          <div>
            <hr className="boarding-pass-rule" />
            <Spinner />
          </div>
        ) : null}
      </div>
    </div>
  )
}

BoardingPassDetails.propTypes = propTypes
export default BoardingPassDetails
