import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as googleTagManager from 'gtm'
import * as api from 'api'
import 'regenerator-runtime/runtime'

const propTypes = {
  cart: PropTypes.object,
  tracked: PropTypes.bool,
  orderToken: PropTypes.string.isRequired,
  confirmationLoadCount: PropTypes.number,
  externalOrderId: PropTypes.string.isRequired,
  webstoreName: PropTypes.string.isRequired,
  webstoreSlug: PropTypes.string.isRequired,
  isReseller: PropTypes.bool.isRequired
}


function sendGtmData(webstoreName, externalOrderId, cart, tracked, isReseller) {
  const lineItems = cart.lineItems
    .filter(li => !li.fee)
    .map((lineItem) => {
      return {
        name: cart.eventName,
        id: lineItem.plu,
        price: lineItem.price,
        brand: webstoreName,
        category: cart.cruiseDisplayName,
        variant: `${googleTagManager.capitalize(lineItem.cruiseType)} - ${googleTagManager.capitalize(
          lineItem.displayName
        )}`,
        quantity: lineItem.quantity,
      }
    })

  googleTagManager.sendPurchaseData(
    webstoreName,
    externalOrderId,
    cart,
    lineItems,
    tracked
  )
}

const OrderAnalytics = ({
  cart,
  tracked,
  confirmationLoadCount,
  orderToken,
  externalOrderId,
  webstoreName,
  webstoreSlug,
  isReseller
}) => {
  useEffect(() => {
    const sessionTrackingKey = `gtmTracked_${externalOrderId}`
    window.dataLayer = window.dataLayer || []
    const gtmTracked = (confirmationLoadCount > 1) || tracked || (sessionStorage.getItem(sessionTrackingKey) === 'true')

    if(!gtmTracked) {
      sessionStorage.setItem(sessionTrackingKey, true)
      api.trackOrderAnalytics(webstoreSlug, orderToken)
        .then(resp => {
          return sendGtmData(webstoreName, externalOrderId, cart, (resp.trackCount > 1 || gtmTracked), isReseller)
        })
        .catch(e => console.log(e))
    }
  }, [])

  return null
}

OrderAnalytics.propTypes = propTypes

export default OrderAnalytics
